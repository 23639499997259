/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Universal
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* Apply styling to all elements (including their `::before` and `::after`
pseudo-elements). One common use of this selector is to apply a custom
`box-sizing`, e.g. as explained here: https://css-tricks.com/?page_id=175473 */
*,
*::before,
*::after {
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Root
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

:root {
  --black: #343433;
  --bg-white: #fcfcfc;
}

html {
  display: flex;
  justify-content: center;
  font-size: 16px !important;
}
body {
  background-color: var(--bg-white);
  color: var(--black);
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div::-webkit-scrollbar {
  width: 0 !important;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Content sectioning
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

h1 {
  font-size: 21px !important;
}

h2 {
  font-size: 18px !important;
}

h3 {
  font-size: 16px !important;
}

h4 {
  font-size: 15px !important;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

header {
  width: 100%;
  position: fixed;
  left: 0;
  height: 4rem;
  z-index: 100;
  background-color: var(--bg-white);
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transition-ease {
  transition: all 300ms ease;
}

header > div {
  height: 100%;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  height: 24px;
}

@media screen and (max-width: 668px) {
  .header {
    padding: 1.5rem 0;
  }

  .header-logo {
    height: 18px;
    margin-left: -8px;
  }

  h1 {
    font-size: 18px !important;
  }

  h2 {
    font-size: 16px !important;
  }

  h3 {
    font-size: 15px !important;
  }

  h4 {
    font-size: 14px !important;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  p,
  span {
    font-size: 14px;
  }
}

nav {
}
nav ul,
nav ol {
}
nav li {
}
nav a {
}
nav a:visited {
}
nav a:hover {
}
nav a:active {
}

main {
}

article {
}

section {
}

aside {
}

footer {
}

address {
}

.click:hover {
  opacity: 0.6;
  cursor: pointer;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Text block content
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* Overall styling, e.g. vertical spacing */
p,
ol,
ul,
pre,
blockquote,
figure,
hr,
dl {
  font-size: 15px;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  white-space: pre-line;
}

ol,
ul {
}
ol {
}
ul {
}
li {
}
ol ol,
ol ul,
ul ul,
ul ol {
}
li li {
}

pre {
}
pre code {
}
pre samp {
}

blockquote {
}

figure {
}
figcaption {
}

hr {
}

dl {
}
dt {
}
dd {
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Inline text semantics
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

a {
}
a:visited {
}
a:hover {
}
a:active {
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Embedded content
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

img {
}

.crop-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.cropped-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}

svg {
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Styles
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.pos-relative {
  position: relative;
}

.paddingHorizontal {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (max-width: 768px) {
  .mobile-hide {
    display: none;
  }

  .paddingHorizontal {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.img-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* *  Hero  * */

.open-button {
  padding: 10px;
  padding-bottom: 10px;
}

.download-badges {
  justify-content: flex-start;
  align-items: center;
}

.app-store-badge {
  height: 47px;
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .open-button {
    padding: 6px;
    padding-bottom: 6px;
  }

  .download-badges {
    justify-content: center;
  }

  .app-store-badge {
    height: 53px;
  }
}

.tab-bar {
  display: flex;
  flex-direction: row;
  /* border-top: 0.5px solid #e4e4e4; */
  box-shadow: 0px 4px 4px 0px #e4e4e4;
  z-index: 2;
  align-items: center;
  padding-top: 4px;
}

/* *  Footer * */
.footer {
  margin: 2rem 0;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-link {
  padding: 1rem;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Google Maps
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.gm-style-mtc,
.gm-fullscreen-control,
.gm-svpc,
.gm-ui-hover-effect {
  display: none !important;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Swiper
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.swiper-slide {
  width: 100% !important;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}

/*
This file evolved from Natural Selection:
https://github.com/frontaid/natural-selection
*/
